<template>
  <div>
    <GenericForm
      title="Equipamento Esterilização"
      previousRoute="equipamento"
      editRoute="equipamentoEditar"
      :model="model"
      :route="equipamentoRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_equipamento"
      :permissionsToWrite="['rw_esterilizacao']"
      :permissionsToEdit="['re_esterilizacao']"
      :permissionsToDelete="['rd_esterilizacao']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Unidade*" label-for="unidade">
              <GenericSelect
                name="unidade"
                labelKey="nome"
                v-model="model.id_unidade"
                :route="unidadeRoute"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('unidade')"
              ></GenericSelect>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Nome do equipamento" label-for="nome">
              <b-form-input
                name="nome"
                id="nome"
                v-model="model.nome"
                autocomplete="off"
                class="invision-input"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('nome')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group
              label="Número do equipamento"
              label-for="numero_equipamento"
            >
              <b-form-input
                name="numero_equipamento"
                id="numero_equipamento"
                v-model="model.numero_equipamento"
                autocomplete="off"
                class="invision-input"
                v-validate="{ required: false }"
                :disabled="isBusy"
                :state="validateState('numero_equipamento')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Marca" label-for="marca">
              <b-form-input
                name="marca"
                id="marca"
                v-model="model.marca"
                autocomplete="off"
                class="invision-input"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('marca')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" lg="4" xl="4">
            <b-form-group label="Modelo" label-for="modelo" label-class="float-left">
              <info-icon
                v-b-tooltip.hover.right="
                  'Modelo descrito na especificação do equipamento'
                "
                class="tooltip-icon"
              />
              <b-form-input
                name="modelo"
                id="modelo"
                v-model="model.modelo"
                autocomplete="off"
                class="invision-input"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('modelo')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="4" xl="4">
            <b-form-group label="Número de série" label-for="numero_serie" label-class="float-left">
              <info-icon
                v-b-tooltip.hover.right="
                  'Número de série descrito na especificação do equipamento'
                "
                class="tooltip-icon"
              />
              <b-form-input
                id="numero_serie"
                v-model="model.numero_serie"
                autocomplete="off"
                class="invision-input"
                name="numero_serie"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('numero_serie')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12" lg="4" xl="4">
            <b-form-group label="Patrimônio" label-for="patrimonio" label-class="float-left">
              <info-icon
                v-b-tooltip.hover.right="
                  'Patrimônio descrito na especificação do equipamento'
                "
                class="tooltip-icon"
              />
              <b-form-input
                name="patrimonio"
                id="patrimonio"
                v-model="model.patrimonio"
                autocomplete="off"
                class="invision-input"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('patrimonio')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="4" xl="4">
            <b-form-group
              label="Esterilização / Desinfecção"
              label-for="esterilizacao"
            >
              <b-form-radio-group
                name="esterilizacao"
                id="esterilizacao"
                ref="esterilizacao"
                v-model="selectedProcessamentos['esterilizacao']"
                :options="processamentoOptions"
                v-validate="{atLeastOne:[state]}"
                :state="validateState('esterilizacao')"
                @change="updateSelectedProcessamento($event,'esterilizacao')"
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
         <b-col sm="12" md="12" lg="4" xl="4">
            <b-form-group
              label="Lavagem Automatizada"
              label-for="lavagem_automatizada"
            >
              <b-form-radio-group
                name="lavagem_automatizada"
                id="lavagem_automatizada"
                ref="lavagem_automatizada"
                v-model="selectedProcessamentos['lavagem_automatizada']"
                :options="processamentoOptions"
                v-validate="{atLeastOne:[state]}"
                :state="validateState('lavagem_automatizada')"
                @change="updateSelectedProcessamento($event,'lavagem_automatizada')"
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row >
            <b-col sm="12" md="12" lg="4" xl="4" v-if="selectedProcessamentos.esterilizacao" >
              <b-form-group
                :label="processamentosParams['esterilizacao'].label"
                :label-for="processamentosParams['esterilizacao'].name"
              >
                <GenericSelect
                  :name="processamentosParams['esterilizacao'].name"
                  labelKey="nome"
                  v-model="
                    model[processamentosParams['esterilizacao'].id_tipo]
                  "
                  :route="processamentosParams['esterilizacao'].route"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="
                    validateState(
                      processamentosParams['esterilizacao'].name
                    )
                  "
                ></GenericSelect>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="4" xl="4" v-if="selectedProcessamentos.lavagem_automatizada" >
              <b-form-group
                :label="processamentosParams['lavagem_automatizada'].label"
                :label-for="processamentosParams['lavagem_automatizada'].name"
              >
                <GenericSelect
                  :name="processamentosParams['lavagem_automatizada'].name"
                  labelKey="nome"
                  v-model="
                    model[processamentosParams['lavagem_automatizada'].id_tipo]
                  "
                  :route="processamentosParams['lavagem_automatizada'].route"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="
                    validateState(
                      processamentosParams['lavagem_automatizada'].name
                    )
                  "
                ></GenericSelect>
              </b-form-group>

            </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  components: {
    GenericForm,
    GenericSelect,
  },

  computed: {
    state() {
      return this.selectedProcessamentos.esterilizacao || this.selectedProcessamentos.lavagem_automatizada;
    },
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateModel(model) {
      this.model = model;
      this.selectedProcessamentos.esterilizacao = this.model.esterilizacao;
      this.selectedProcessamentos.lavagem_automatizada = this.model.lavagem_automatizada;
    },

    updateSelectedProcessamento(event, tipoProcessamento) {
      this.model[tipoProcessamento] = event;
      if (!event) {
        const { id_tipo } = this.processamentosParams[tipoProcessamento];
        this.model[id_tipo] = null;
      }
    },
  },

  data() {
    return {
      model: {
        id_equipamento: this.$route.params.id || -1,
        id_unidade: null,
        nome: '',
        numero_equipamento: '',
        marca: '',
        modelo: '',
        numero_serie: '',
        patrimonio: '',
        esterilizacao: false,
        lavagem_automatizada: false,
        id_tipo_esterilizacao: null,
        id_tipo_lavagem_automatizada: null,
      },

      equipamentoRoute: 'equipamento',
      unidadeRoute: 'unidade',

      processamentoOptions: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],

      selectedProcessamentos: {
        esterilizacao: false,
        lavagem_automatizada: false,
      },

      processamentosParams: {
        esterilizacao: {
          tipoProcessamento: 'esterilizacao',
          label_radio: 'Esterilização',
          name: 'tipoEsterilizacao',
          label: 'Tipo de Esterilização ou Desinfecção',
          route: 'tipoEsterilizacao',
          id_tipo: 'id_tipo_esterilizacao',
        },
        lavagem_automatizada: {
          tipoProcessamento: 'lavagem_automatizada',
          label_radio: 'Lavagem Automatizada',
          name: 'tipoLavagemAutomatizada',
          label: 'Tipo de Lavagem Automatizada',
          route: 'tipoLavagemAutomatizada',
          id_tipo: 'id_tipo_lavagem_automatizada',
        },
      },
    };
  },
};
</script>
